<template>
	<div class="">
		<div id="">
			<el-image style="height: 400px; width: 100%" :src="require('@/assets/images/cc.jpg')"></el-image>
		</div>
		<div class="page-content" style="margin-top: 40px;">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>翻译润色</el-breadcrumb-item>
					<el-breadcrumb-item>查重服务</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div>
				<p style="color: #333333;font-weight: bold;">◆ 业务简介</p>
				<div style="padding-left:80px;margin-top: 20px; line-height: 26px;">
					我们提供本科毕业论文、期刊论文、硕、博研究生论文、会议论文降低重复率，格式调整等服务。10年来，已经帮助数万位学子解决论文抄袭率，论文格式，论文重复率高等难题！强大的一手老师资源，首创安全保密降重流程，充分保障客户，隐私和利益！
				</div>
				<el-divider></el-divider>
			</div>
			<div class="loading">
				<p style="color: #333333;font-weight: bold;">◆ 适合稿件</p>
				<div>
					<p style="margin-left: 80px;">适合本硕博毕业论文或学术论文，各类中高级职称论文及学术研究报告</p>
					<div style="border: solid 1px #eeeeee;margin: 40px auto;width: 80%;">
						<div style="margin-top: 40px;">
							<div style="text-align: center;">
								<p style="color: #333333;font-size: 24px;text-align: center;">论文查重委托书</p>
								<el-divider style="margin: auto;width: 80%;"></el-divider>
							</div>
							<div style="width: 80%;margin: 20px auto;">
								<el-form ref="releaseForm" :model="releaseForm" :rules="rules" :inline="true"
									label-width="80px">
									<el-form-item label="委托人" style="width: 48%;" required prop="authorName">
										<el-input v-model="releaseForm.authorName"></el-input>
									</el-form-item>
									<el-form-item label="委托单位" style="width: 48%;" required prop="unitName">
										<el-input v-model="releaseForm.unitName"></el-input>
									</el-form-item>
									<el-form-item label="联系邮箱" style="width: 48%;" required prop="eMail">
										<el-input v-model="releaseForm.eMail"></el-input>
									</el-form-item>
									<el-form-item label="联系电话" style="width: 48%;" required prop="phone">
										<el-input v-model="releaseForm.phone"></el-input>
									</el-form-item>
									<el-form-item style="width: 100%;">
										<el-upload class="upload-demo" ref="upload"
											action="http://47.97.203.109:8066/upload/file" :on-remove="handleRemove"
											:on-success="onSuccess" multiple :limit="1" :on-exceed="handleExceed"
											:file-list="fileList">
											<el-button size="medium" type="info">上传文档</el-button>
											<template #tip>
												<div class="el-upload__tip" style="color: red">
													注：上传内容建议去除封面、目录、附录，仅上传正文
												</div>
											</template>
										</el-upload>
									</el-form-item>
									<el-form-item style="margin-left: 40%;">
										<el-button type="primary" @click="onSubmit('releaseForm')"
											style="background-color: #18305b; width: 185px">提交</el-button>
									</el-form-item>
								</el-form>
								<div style="">
									<p style="color: #c00000;">收费标准：</p>
									<div style="line-height: 8px;font-size: 14px;margin-top: 20px;margin-bottom: 60px;">
										<p>1. 检测系统支持.zip .rar .doc .docx .txt .pdf等格式。</p>
										<p>2. 上传的论文文档，请按此格式命名：“作者姓名_文档名称”。</p>
										<p>3. 单篇文章大小不得超过10M，字符数不得超过10W，批量检测压缩包不得大于100M。</p>
										<p>4. 检测结束后，工作人员会把检测报告发送至委托人邮箱，请注意查收。</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 100px;">
				<p style="color: #333333;font-weight: bold;">◆ 保密协议</p>
				<p style="text-indent: 100px;">
					IAMSET尊重知识产权，每一位IAMSET工作人员都经历过严格系统的职业道德培训以及签订了具有法律效应的《保密协议》。文件数据传输过程中经历多个安全层，确保文章信息不泄露。</p>
			</div>
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				releaseForm: {
					authorName: "",
					eMail: "",
					phone: "",
					unitName: "",
					spareFieldO: "",
					spareFieldT: "cc"
				},
				rules: {
					authorName: [{
						required: true,
						trigger: "blur",
						message: "请输入委托人"
					}, ],

					eMail: [{
						required: true,
						trigger: "blur",
						message: "请输入联系邮箱"
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						message: "请输入联系电话"
					}, ],
					unitName: [{
						required: true,
						trigger: "blur",
						message: "请输入委托单位"
					}, ],

				},
				fileList: [],
			};
		},

		methods: {
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
					 files.length + fileList.length
					} 个文件`
				);
			},
			onSuccess(esponse, file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = this.fileList[0].response.data
			},
			handleRemove(file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = null
			},
			onSubmit(releaseForm) {
				this.$refs[releaseForm].validate((valid) => {
					if (valid) {
						let _this = this;
						$http
							.post("addOnlineSubmission", this.releaseForm, "loading")
							.then(function(res) {
								console.log(res)
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.$refs[releaseForm].resetFields();
											_this.$refs. ['upload'].clearFiles();
										},
									});
								}

							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
		},
		created() {

		},
		mounted() {

		},

	};
</script>

<style lang="scss" scoped>

</style>
